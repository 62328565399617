import React from 'react';
import UspsLogo from '@reverbdotcom/cadence/images/vendor/usps-logo.svg';
import UspsLogoWhite from '@reverbdotcom/cadence/images/vendor/usps-logo-white.svg';
import UpsLogo from '@reverbdotcom/cadence/images/vendor/ups-logo.svg';
import I18n from 'i18n-js';

import { RCImage } from '@reverbdotcom/cadence/components';

export function CarrierHighlights() {
  return (
    <div className="carrier-highlights">
      <div className="carrier-highlights__row">
        <img src={UpsLogo} className="carrier-highlights__logo carrier-highlights__logo__ups" alt="UPS" />
        <div className="carrier-highlights__text">
          {I18n.t('discovery.sellForm.shippingSection.carrierHighlights.upsDescription')}
        </div>
      </div>
      <div className="carrier-highlights__row">
        <div className="carrier-highlights__logo carrier-highlights__logo__usps">
          <RCImage
            src={UspsLogo}
            darkModeSrc={UspsLogoWhite}
            alt="USPS"
            imageBox="auto"
          />
        </div>
        <div className="carrier-highlights__text">
          {I18n.t('discovery.sellForm.shippingSection.carrierHighlights.uspsDescription')}
        </div>
      </div>
    </div>
  );
}
